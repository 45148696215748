module.exports = [{
      plugin: require('/Users/Chris/Library/Mobile Documents/com~apple~CloudDocs/DUSHIYU-GB/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132146313-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/Users/Chris/Library/Mobile Documents/com~apple~CloudDocs/DUSHIYU-GB/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/Chris/Library/Mobile Documents/com~apple~CloudDocs/DUSHIYU-GB/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
