/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

  exports.onInitialClientRender = () => {
      if(document.getElementById('eventFormButton')){
    document.getElementById('eventFormButton').innerHTML = '<noscript><a href="https://www.eventbrite.nl/e/tickets-dushi-yu-xxl-60894559266" rel="noopener noreferrer" target="_blank"></noscript>  <button class="button" id="eventbrite-widget-modal-trigger-60894559266" type="button">KOOP JE TICKETS</button> <noscript></a>KOOP JE TICKETS</noscript>';
    var exampleCallback = function() {
        console.log('Order complete!');
    };
    window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '60894559266',
        modal: true,
        modalTriggerElementId: 'eventbrite-widget-modal-trigger-60894559266',
        onOrderComplete: exampleCallback
    });
};
}